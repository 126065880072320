<div class="p-6 flex flex-col items-center gap-y-6 text-center">
  @if (_data.icon; as icon) {
  <i
    class="-mb-2"
    [class.text-success]="icon === 'success'"
    [essIcon]="_confirmationDialogIconDictionary[icon]"
    [essIconSize]="'xxl'"
  ></i>
  }
  <h1 class="text-headline-xs whitespace-pre-line">{{ _data.title }}</h1>
  @if (_data.subtitle) {
  <p class="text-center whitespace-pre-line text-lg text-base-secondary">
    {{ _data.subtitle }}
  </p>
  } @if (_data.innerHTML; as html) {
  <div [innerHTML]="html | trustedHtml"></div>
  }
  <div class="flex justify-between gap-3 self-stretch">
    @if (_data.actions.cancel) {
    <ess-shared-ui-button class="grow" [variant]="'outline'" (click)="_dialogRef.close()">
      {{ _data.actions.cancel }}
    </ess-shared-ui-button>
    } @if (_data.actions.submit) {
    <ess-shared-ui-button
      class="grow"
      [variant]="_data.actions.submitButtonVariant ?? 'primary'"
      (click)="_dialogRef.close(true)"
    >
      {{ _data.actions.submit }}
    </ess-shared-ui-button>
    }
  </div>
</div>
