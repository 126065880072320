<div class="flex flex-col h-full w-full">
  @if (hasHeader) {
  <div
    class="p-6 flex justify-between items-center relative gap-x-6 border-b border-neutral-variant-400"
    [ngClass]="headerClass"
  >
    <span class="text-headline-xs">
      {{ header }}
    </span>
    <div class="grow">
      <ng-content select="[header]" />
    </div>
    <ess-shared-ui-icon-button [icon]="'close'" [size]="'sm'" [disabled]="loading" (click)="_actionHandler('close')" />
  </div>
  }

  <div
    class="p-6 grow relative {{ loading ? 'overflow-hidden' : 'overflow-y-auto overflow-x-hidden' }}"
    [ngClass]="contentClass"
  >
    <ng-content />
    @if (loading) {
    <ess-shared-ui-loading-spinner overlay />
    }
  </div>

  @if (hasFooter) {
  <div class="flex justify-between items-center px-6 py-4 border-t border-neutral-variant-400">
    <ess-shared-ui-button
      [class.m-auto]="!submitButtonText"
      [variant]="'outline'"
      [disabled]="loading"
      (click)="_actionHandler('cancel')"
      >{{ cancelButtonText }}
    </ess-shared-ui-button>
    <ng-content select="[footer]" />
    @if (submitButtonText) {
    <ess-shared-ui-button [disabled]="submitDisabled || !!loading" (click)="_actionHandler('submit')"
      >{{ submitButtonText }}
    </ess-shared-ui-button>
    }
  </div>
  }
</div>
