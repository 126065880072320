import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { map, Observable } from 'rxjs';

import { SharedStore } from '@ess/shared/utils/models';

export interface StoreProps {
  menuCollapsed: boolean;
}

type Store = SharedStore<StoreProps>;

@Injectable({ providedIn: 'root' })
export class SharedFeatureServicesNavigationRepository {
  private readonly __store: Store;

  get menuCollapsed$(): Observable<boolean> {
    return this.__store.pipe(select((state) => state.menuCollapsed));
  }

  get sidebarSize$(): Observable<number> {
    return this.menuCollapsed$.pipe(map((collapsed) => (collapsed ? 4.5 : 15.5)));
  }

  constructor() {
    this.__store = this.__createStore();
  }

  toggleMenu(): void {
    this.__store.update((state) => ({ ...state, menuCollapsed: !this.__store.getValue().menuCollapsed }));
  }

  private __createStore(): Store {
    return createStore(
      { name: 'EssFeatureServicesNavigation' },
      withProps<StoreProps>({
        menuCollapsed: false,
      }),
    );
  }
}
