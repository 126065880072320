import { Clipboard } from '@angular/cdk/clipboard';
import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { BehaviorSubject, filter, map, merge, Observable, of } from 'rxjs';

import { EssSnackBarConfig, SharedUiComponentsSnackbarComponent } from '@ess/shared/ui/components';
import { SharedUiDialogsConfirmationComponent, SharedUiDialogsLoadingComponent } from '@ess/shared/ui/dialogs';
import { SharedDialogSizeEnum } from '@ess/shared/utils/enums';
import {
  SharedConfirmationDialogData,
  SharedDialogConfig,
  SharedDialogRef,
  SharedUserInteractionService,
} from '@ess/shared/utils/models';

@Injectable()
export class SharedFeatureServicesUserInteractionService implements SharedUserInteractionService {
  readonly outletScrolledAmount$ = new BehaviorSubject<number>(0);
  readonly bottomBarOpened$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly __dialog: MatDialog,
    private readonly __snackBar: MatSnackBar,
    private readonly __clipboard: Clipboard,
  ) {}

  openDialog<T, D>(component: ComponentType<T>, extendedConfig?: SharedDialogConfig<D>): SharedDialogRef<T> {
    const config: SharedDialogConfig<D> = {
      autoFocus: false,
      disableClose: !!extendedConfig?.detectClose,
      panelClass: 'ess-dialog',
      maxHeight: '90vh',
      maxWidth: '95vw',
      ...extendedConfig,
    };
    const dialog: SharedDialogRef<T> = this.__dialog.open(component, config);
    if (config.detectClose) {
      dialog.closed$ = merge(
        dialog.backdropClick(),
        dialog.keydownEvents().pipe(filter((event) => event.key === 'Escape')),
      ).pipe(map((event) => !!event));
    }
    return dialog;
  }

  openConfirmationDialog(
    data: SharedConfirmationDialogData,
    config?: Omit<MatDialogConfig, 'data'>,
  ): MatDialogRef<SharedUiDialogsConfirmationComponent> {
    return this.openDialog<SharedUiDialogsConfirmationComponent, SharedConfirmationDialogData>(
      SharedUiDialogsConfirmationComponent,
      { data, width: SharedDialogSizeEnum.XS, disableClose: true, ...config },
    );
  }

  openLoadingDialog(title: string): MatDialogRef<SharedUiDialogsLoadingComponent> {
    return this.openDialog(SharedUiDialogsLoadingComponent, {
      data: title,
      width: SharedDialogSizeEnum.XS,
      disableClose: true,
    });
  }

  openUnsavedChangesDialog(formDirty = true): Observable<boolean> {
    return formDirty
      ? this.openConfirmationDialog({
          title: 'Are you sure you want to leave?',
          subtitle: 'Unsaved changes will be lost.',
          actions: {
            submit: 'Confirm and leave',
            cancel: 'Keep editing',
          },
        })
          .afterClosed()
          .pipe(map((confirmed) => !!confirmed))
      : of(true);
  }

  openSnackbar(config: MatSnackBarConfig<EssSnackBarConfig>): MatSnackBarRef<SharedUiComponentsSnackbarComponent> {
    return this.__snackBar.openFromComponent(SharedUiComponentsSnackbarComponent, {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: ['ess-snackbar', `ess-snackbar--${config.data?.type}`],
      ...(config.data?.type !== 'error' && { duration: 3000 }),
      ...config,
    });
  }

  openLink(url: string | null | undefined, newTab = true): void {
    url && window.open(url, newTab ? '_blank' : '_self', 'noopener,noreferrer');
  }

  copyText(text: string | null | undefined, confirmationText?: string): void {
    if (text) {
      this.__clipboard.copy(text);
      this.openSnackbar({ data: { type: 'success', text: confirmationText || 'Copied to clipboard!' } });
    }
  }
}
