import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { untilDestroyed } from '@ngneat/until-destroy';
import { filter, Observable, of, switchMap } from 'rxjs';

import { SharedDialogRef } from '@ess/shared/utils/models';

@Component({
  template: '',
})
export abstract class AbstractSharedUiDialogsDiscardableComponent implements OnInit {
  protected abstract _dirty: boolean;
  protected _focused = true;
  constructor(protected _dialogRef: MatDialogRef<unknown>) {}

  ngOnInit(): void {
    this.__initDialogCloseSub();
  }

  protected abstract _openUnsavedChangesDialog(): Observable<boolean>;

  private __initDialogCloseSub(): void {
    (this._dialogRef as SharedDialogRef<unknown>).closed$
      ?.pipe(
        switchMap(() => (this._dirty ? this._openUnsavedChangesDialog() : of(true))),
        filter(() => this._focused),
        untilDestroyed(this),
      )
      .subscribe(() => this._dialogRef.close());
  }
}
