import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, Subject } from 'rxjs';

import { SharedUiButtonComponent, SharedUiIconButtonComponent } from '@ess/shared/ui/button';
import { SharedUiIconDirective } from '@ess/shared/ui/icons';
import { SharedUiLoadingSpinnerComponent } from '@ess/shared/ui/loading';

type DialogAction = 'submit' | 'cancel' | 'close';

@UntilDestroy()
@Component({
  selector: 'ess-shared-ui-dialogs-custom',
  standalone: true,
  imports: [
    CommonModule,
    SharedUiButtonComponent,
    SharedUiIconDirective,
    SharedUiIconButtonComponent,
    SharedUiLoadingSpinnerComponent,
  ],
  templateUrl: './shared-ui-dialogs-custom.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: ':host { @apply h-full }',
})
export class SharedUiDialogsCustomComponent implements OnInit {
  @Input() header = '';
  @Input() submitButtonText = '';
  @Input() cancelButtonText = 'Cancel';
  @Input() submitDisabled = false;
  @Input() loading: boolean | null = false;
  @Input() hasHeader = true;
  @Input() hasFooter = true;
  @Input() headerClass = '';
  @Input() contentClass = '';

  @Output() dispatch: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() submitted: EventEmitter<void> = new EventEmitter<void>();
  @Output() cancelled: EventEmitter<void> = new EventEmitter<void>();
  @Output() closed: EventEmitter<void> = new EventEmitter<void>();

  private readonly __action$: Subject<DialogAction> = new Subject<DialogAction>();

  ngOnInit(): void {
    this.__listenButtonClicks();
  }

  protected _actionHandler(action: DialogAction): void {
    this.__action$.next(action);
  }

  private __listenButtonClicks(): void {
    this.__action$.pipe(debounceTime(250), untilDestroyed(this)).subscribe((action: DialogAction) => {
      this.dispatch.emit(action === 'submit');
      switch (action) {
        case 'submit':
          this.submitted.emit();
          break;
        case 'cancel':
          this.cancelled.emit();
          break;
        case 'close':
          this.closed.emit();
          break;
      }
    });
  }
}
