import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { SharedUiLoadingSpinnerComponent } from '@ess/shared/ui/loading';

@Component({
  standalone: true,
  imports: [SharedUiLoadingSpinnerComponent],
  template: `
    <div class="p-6 flex flex-col items-center">
      <h1 class="text-headline-xs whitespace-pre-line mb-8">{{ _title }}</h1>
      <ess-shared-ui-loading-spinner />
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedUiDialogsLoadingComponent {
  protected readonly _title = inject(MAT_DIALOG_DATA);
}
