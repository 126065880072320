import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { SharedUiButtonComponent } from '@ess/shared/ui/button';
import { SharedUiIconDirective } from '@ess/shared/ui/icons';
import { SharedConfirmationDialogData } from '@ess/shared/utils/models';
import { TrustedHtmlPipe } from '@ess/shared/utils/pipes';

@Component({
  standalone: true,
  imports: [SharedUiButtonComponent, TrustedHtmlPipe, SharedUiIconDirective],
  templateUrl: './shared-ui-dialogs-confirmation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedUiDialogsConfirmationComponent {
  protected _confirmationDialogIconDictionary = { success: 'check_circle' };
  constructor(
    @Inject(MAT_DIALOG_DATA) protected _data: SharedConfirmationDialogData,
    protected _dialogRef: MatDialogRef<SharedUiDialogsConfirmationComponent>,
  ) {}
}
